






















































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import SigninModel from '@/models/signinModel';
import { displayMessage, apiMessage, inputElement } from '@/constants/signin';

@Component({
  components: { SingleText }
})
export default class Forget extends Vue {
  private signinModel = new SigninModel();

  /** 画面表示文言の定義オブジェクト */
  define = {};
  /** フォーム要素文言の定義オブジェクト */
  inputElement = {};

  /** フォーム入力: メールアドレス */
  email = '';

  /** ローディングスピナー表示状態（true: 表示中） */
  isLoading = false;
  /** 入力されたメールアドレスがHexabase上に存在するかどうか。存在する場合はtrue */
  valid = false;
  /** 各種エラーメッセージ */
  errorMessage = '';

  created() {
    // 外部定義をプロパティーに追加
    this.define = displayMessage.forget;
    this.inputElement = inputElement.forget;
  }

  /**
   * パスワード再設定リクエストを送信
   * @returns void
   */
  async sendForget() {
    try {
      this.errorMessage = '';
      this.isLoading = true;

      const response = await this.signinModel.requestPassReset(this.email);

      if ('valid_email' in response && response.valid_email) {
        this.valid = true;
      } else {
        this.errorMessage = apiMessage.noValidEmail;
      }
    } catch (e) {
      this.errorMessage = apiMessage.fail;
    } finally {
      this.isLoading = false;
    }
  }
}
